import React from "react";

const Header6xl = ({ children, textColor, textAlign }) => {

  return (
    <h1 className={`mt-0 text-4xl sm:text-6xl ${textColor} ${textAlign}`}>
      {children}
    </h1>
  )

}

export default Header6xl