import React, { useRef, useEffect } from 'react'
import useChartDimensions from '../../hooks/useChartDimensions'
import * as d3 from 'd3'

const LineChart = ({
  chartTitle, 
  titleStyling,
  data,
  domainLower,
  domainUpper,
  xFormat,
  yFormat,
  containerStyling,
  lineColor,
  lineWidth,
}) => {
  
  const wrapperRef = useRef()
  const yAxisRef = useRef()
  const xAxisRef = useRef()
  const lineRef = useRef()
  const dimensions = useChartDimensions(wrapperRef)

  const dateParser = d3.timeParse(xFormat)
  const xAccessor = d => dateParser(d.x) 
  
  const xScale = d3.scaleTime()
    .domain(d3.extent(data, xAccessor))
    .range([0, dimensions.width])
  
  const yScale = d3.scaleLinear()
    .domain([domainLower,domainUpper])
    .range([dimensions.height, 0])
  
  const lineGenerator = d3.line()
    .x(d => xScale(xAccessor(d)))
    .y(d => yScale(d.y))
  
  const xAxisGenerator = d3.axisBottom()
    .scale(xScale)
    .ticks(dimensions.width / 100)
  
  const yAxisGenerator = d3.axisLeft()
    .scale(yScale)
    .tickFormat(d3.format(yFormat))

  
  useEffect(() => {

    const yAxisG = d3.select(yAxisRef.current)
      .join("g")

    const xAxisG = d3.select(xAxisRef.current)
      .join("g")
        .style("transform", `translateY(${dimensions.height}px)`)

    yAxisGenerator(yAxisG) 
    xAxisGenerator(xAxisG)

    d3.select(lineRef.current)
      .join("path")
        .attr("d", lineGenerator(data))
        .attr("fill", "none")
        .attr("stroke", lineColor)
        .attr("stroke-width", lineWidth)
    
  }, [dimensions])


  return (
    <div 
      ref={wrapperRef} 
      id={"responsive-div-wrapper"}
      className={containerStyling}
    >
      <div className="mb-2">
        <p className={titleStyling}>{chartTitle}</p>
      </div>
      <svg
      className="w-full h-full overflow-visible"
      id={"svg-wrapper"}
      >
        <g id={"axes-group"}>
          <g id={"y-group"} ref={yAxisRef}></g>
          <g id={"x-group"} ref={xAxisRef}></g>
        </g>
        <g id={"line-group"}>
          <path id={"line"} ref={lineRef}></path>
        </g>
      </svg>
    </div>
  )
}

export default LineChart