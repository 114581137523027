// note: I removed the <Layout> component and replaced it with a no-index header
// so that this page isn't indexed by Google. 

import * as React from 'react'
import { ExplanatoryText, ScrollContainer, ScrollArticle, ScrollStep } from '../../components/scrolly'
import ContainerCentered from '../../components/container-centered'
import Header6xl from '../../components/header-6xl'
import LineChart from '../../charts/lines/line-chart'
// import UsMapDots from '../../charts/maps/us-map-dots'
import data from '../../data/coal-in-america/us-historical-coal-emissions.json' 

const CoalHistory = () => {

  return (
    // remove this section to bring back nav bar
    <div>
    <head>
      <meta name="robots" content="noindex" />
    </head>

      <section id="hero">
        <ContainerCentered bgColor={"bg-slate-100"} minHeight={"min-h-screen"} maxWidth={"max-w-4xl"}>
          <Header6xl textAlign={"text-center"}>Coal history data visualization</Header6xl>
        </ContainerCentered>
      </section>  

      <section>
        <ExplanatoryText>
          <h2>Introduction</h2>
          <p>Ever since coal emissions peaked in 2012 they've fallen quickly. But in 2020 coal plants still
          emitted about 1 billion tons per year. That's 21% of the country's total.</p>
          <p>And while the coal industry is certainly not going to roar back to life anytime soon,
          emissions from coal power plants are expected to be as high as 400m tons in 2030.</p>
        </ExplanatoryText>
        <ScrollContainer>
          <LineChart
            chartTitle={"CO2 emissions from coal-fired power plants in the U.S. (gigatons per year)"}
            titleStyling={"font-light text-base"}
            xFormat={"%Y"}
            data={data}
            domainLower={500000000}
            domainUpper={2500000000}
            yFormat={".2s"}
            containerStyling={"sticky mx-auto mb-10vh w-3/4 top-10vh h-50vh bottom-40vh sm:top-10vh sm:h-70vh sm:bottom-20vh"}
            lineColor={"#2F394B"}
            lineWidth={2}
           />
          <ScrollArticle>
            <ScrollStep>
              <p>Coal emissions began declining in America for two main reasons: </p>
              <ol>
                <li>The fracking boom in America that led to a fall in natural gas prices; </li>
                <li> The dramatic fall in the price of renewable energy.</li>
              </ol>
              <p> Let's look at how those two changes affected coal emissions.</p>
            </ScrollStep>
            <ScrollStep>
              <p>Coal emissions began declining in America for two main reasons: </p>
              <ol>
                <li>The fracking boom in America that led to a fall in natural gas prices; </li>
                <li> The dramatic fall in the price of renewable energy.</li>
              </ol>
              <p> Let's look at how those two changes affected coal emissions.</p>
            </ScrollStep>
          </ScrollArticle>
        </ScrollContainer>
        <ExplanatoryText>
          <h2>Introduction</h2>
          <p>Ever since coal emissions peaked in 2012 they've fallen quickly. But in 2020 coal plants still
          emitted about 1 billion tons per year. That's 21% of the country's total.</p>
          <p>And while the coal industry is certainly not going to roar back to life anytime soon,
          emissions from coal power plants are expected to be as high as 400m tons in 2030.</p>
        </ExplanatoryText>
        <ScrollContainer>
          {/* <UsMapDots
            data={plantsToMap}
            dotSizeAccessor={plantEmissions}
            tooltipDataAccessor={plantEmissions}
            rangeLower={250}                // this gets divided by screen width 
            rangeUpper={50}                // this gets divided by screen width 
            mapFill={"#E0E2E4"}
            mapStroke={"#F5F5F6"}
            dotFill={"#2F394B"}
            dotOpacity={0.7}
            containerStyling={"sticky mx-auto m-0 top-25vh sm:top-1vh w-3/4 h-50vh sm:h-90vh bottom-25vh sm:bottom-10vh"}
           /> */}
          <ScrollArticle>
            <ScrollStep>
              <p>Coal emissions began declining in America for two main reasons: </p>
              <ol>
                <li>The fracking boom in America that led to a fall in natural gas prices; </li>
                <li> The dramatic fall in the price of renewable energy.</li>
              </ol>
              <p> Let's look at how those two changes affected coal emissions.</p>
            </ScrollStep>
            <ScrollStep>
              <p>Coal emissions began declining in America for two main reasons: </p>
              <ol>
                <li>The fracking boom in America that led to a fall in natural gas prices; </li>
                <li> The dramatic fall in the price of renewable energy.</li>
              </ol>
              <p> Let's look at how those two changes affected coal emissions.</p>
            </ScrollStep>
          </ScrollArticle>
        </ScrollContainer>
      </section>
      <section id="dummy-section" className="min-h-screen min-w-full mb-40 bg-slate-100"></section>
    </div>
  )

}

export default CoalHistory
